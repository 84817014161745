import { Injectable, Renderer2, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UiControllService {
  // private destroy$ = new Subject<void>();
  currentRoute: any;
  previousPageClass: string = '';
  private renderer: Renderer2 | undefined;

  constructor(private router: Router) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.updateBodyClass(event.url);
        window.scrollTo(1000, 0);
      });
  }

  private scrollSubject = new BehaviorSubject<string>('');
  scrollToElement(elementId: string): void {
    this.scrollSubject.next(elementId);
  }

  getScrollSubject() {
    return this.scrollSubject.asObservable();
  }

  private updateBodyClass(url: string): void {
    const body = document.body;
    // Extract the page name from the URL (you may need to adjust this logic based on your routing structure)
    let pageName;
    if(url.includes('/pickup-store') || url.includes('/delivery')){
      pageName = url.split('/').filter(Boolean).shift() || 'home';
    }else{
      pageName = url.split('/').filter(Boolean).pop() || 'home';
    }

    // Remove any existing body classes
    body.className = '';

    // Add the class based on the page name
    body.classList.add(`page-${pageName}`);
    window.scrollTo(1000, 0);
  }
}
