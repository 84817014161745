import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient) {}
  post(serviceName: string, data: any) {
    const headers = new HttpHeaders({
      'isweb':'true',
      'Cache-Control': 'max-age=86400'

    });
    const options = { headers: headers, withCredintials: false };
    const url = environment.apiUrls + serviceName;

    return this.http.post(url, data, options);
  }



  puts(serviceName: string, data: any) {
    const headers = new HttpHeaders({
      'isweb':'true',
      'Cache-Control': 'max-age=86400'
    });
    const options = { headers: headers, withCredintials: false };
    const url = environment.apiUrls + serviceName;

    return this.http.put(url, data, options);
  }

  get(serviceName: string) {
    const headers = new HttpHeaders({
      'isweb':'true',
      'Cache-Control': 'max-age=86400'
    });
    const options = { headers: headers, withCredintials: false };
    const url = environment.apiUrls + serviceName;

    return this.http.get(url,options);
  }

  gets(serviceName: string, token) {
    const headers = {
      'x-auth': localStorage.getItem('token'),
      'isweb':'true',
      'Cache-Control': 'max-age=86400'
    };
    const options = { headers: headers };
    const url = environment.apiUrls + serviceName;

    return this.http.get(url, options);
  }
  refreshToken(serviceName: string, token) {
    const headers = {
      'x-auth': token,
      'isweb':'true',
      'Cache-Control': 'max-age=86400'
    };
    const options = { headers: headers };
    const url = environment.apiUrls + serviceName;

    return this.http.get(url, options);
  }

  posts(serviceName: string, data: any, token) {
    const headers = {
      'x-auth': localStorage.getItem('token'),
      'Access-Control-Allow-Origin': '*',
      'isweb':'true',
      'Cache-Control': 'max-age=86400'
    };
    const options = {
      headers: headers,
    };
    const url = environment.apiUrls + serviceName;

    return this.http.post(url, data, options);
  }

  postss(serviceName: string) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'isweb':'true',
      'Cache-Control': 'max-age=86400'
    };
    const options = {
      headers: headers,
    };
    const url = environment.apiUrls + serviceName;

    return this.http.post(url, options);
  }

  postRecipient(serviceName: string, data:any) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'isweb':'true',
      'x-auth': localStorage.getItem('token') || '',
      'Cache-Control': 'max-age=86400'
    };
    const options = {
      headers: headers,
    };
    const url = environment.apiUrls + serviceName;

    return this.http.post(url, data, options);
  }

  put(serviceName: string, data: any, token) {
    const headers = {
      'x-auth': localStorage.getItem('token'),
      'isweb':'true',
      'Cache-Control': 'max-age=86400'
    };
    const options = { headers: headers };
    const url = environment.apiUrls + serviceName;

    return this.http.put(url, data, options);
  }

  delete(serviceName: string, token) {
    const headers = {
      'x-auth': localStorage.getItem('token'),
      'isweb':'true',
      'Cache-Control': 'max-age=86400'
    };
    const options = { headers: headers };
    const url = environment.apiUrls + serviceName;

    return this.http.delete(url, options);
  }
  getPdf(url) {

    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    return this.http.get(url, httpOptions);
  }
  getsWeb(serviceName: string, token) {
    const headers = {
      'x-auth': localStorage.getItem('token'),
      'isweb':'true',
      'Cache-Control': 'max-age=86400'
    };
    const options = { headers: headers };
    const url = environment.apiUrlWeb + serviceName;

    return this.http.get(url, options);
  }
  postsWeb(serviceName: string, data: any, token) {
    const headers = {
      'x-auth': localStorage.getItem('token'),
      'Access-Control-Allow-Origin': '*',
      'isweb':'true',
      'Cache-Control': 'max-age=86400'
    };
    const options = {
      headers: headers,
    };
    const url = environment.apiUrlWeb + serviceName;

    return this.http.post(url, data, options);
  }
}
