import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(public toastController: ToastController) { }

  async presentToast(infoMessage: string) {
    const toast = await this.toastController.create({
    message: infoMessage,
    duration: 2000,
    position: 'top',
    color: 'light',
    cssClass: 'toast-custom-class',
    buttons: [
      {
        icon: 'close-outline',
      }
    ]
    });
    toast.present();
    }
    closeToaster(){
      this.toastController.dismiss()
    }
}

