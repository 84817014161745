import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
const stores = new Storage();
stores.create();
@Injectable({
  providedIn: 'root',
})
export class StorageServiceService {
  private _storage: Storage | null = null;
  public previousUrl = ""
  constructor(private storage: Storage) {
    //this.init();
  }

  // async init() {
  //   // If using, define drivers here: await this.storage.defineDriver(/*...*/);
  //   const storage = await this.storage.create();
  //   this._storage = storage;
  // }

  // Store the value
  async store(storageKey: string, value: any) {
    const encryptedValue = btoa(escape(JSON.stringify(value)));
   return await stores.set(storageKey, encryptedValue);
  }

  // Get the value
  async get(storageKey: string) {
    const ret = await stores.get(storageKey);
    if (ret == null) {
      return ret;
    } else {
      return JSON.parse(unescape(atob(ret)));
    }
  }

  async removeStorageItem(storageKey: string) {
    // console.log(storageKey);
    await stores.remove(storageKey);
    await stores.clear();
    localStorage.removeItem('token');
  }

  async removeStorageByKey(storageKey: string) {
    // console.log(storageKey);
    await stores.remove(storageKey);
  }


  // Clear storage
  async clear() {
    await stores.clear();
  }
  //store value in local storage
  setToken(data) {
    localStorage.setItem('email', data);
  }
  //get the token
  getToken(): string {
    return localStorage.getItem('token');
  }

}
