import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/shared/services/data.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { pipeline } from 'stream';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor( private dataService: DataService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private notifier: NotifierService) { }
    isSubmitted: boolean = false;
    msg: boolean = false;
  newContactForm = this.fb.group({
    firstname: new FormControl('', Validators.required),
  //  lastname: new FormControl(''),
    email: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),
    address: new FormControl('', Validators.required),
    subject: new FormControl('', Validators.required),
    message: new FormControl('', Validators.required),
  });

  ngOnInit(): void {
    
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.newContactForm.invalid) {
      this.newContactForm.markAllAsTouched();
    }else {
      let payload = {
        firstname: this.newContactForm.value.firstname,
       // lastname: this.newContactForm.value.lastname,
        email: this.newContactForm.value.email,
        phone: this.newContactForm.value.phone,
        address: this.newContactForm.value.address,
        subject: this.newContactForm.value.subject,
        message: this.newContactForm.value.message
      };
      //  console.log(payload);
      this.dataService.addContact(payload).subscribe(
        (res) => {
          this.msg =true
          this.isSubmitted = false;
          this.newContactForm.reset();
          this.notifier.notify('success', 'contact information has been created!');
         // this.router.navigate(['/#']);
        },
        (error) => {
          this.isSubmitted = false;
  
          console.error('Error!', error);
        }
      );
    }
    
  }

}
