import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route, Router } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomPreloadingStrategy implements PreloadingStrategy {
  constructor(private router:Router){}
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    const preload = this.router.url;
    if (preload === '/') {
      return of(null);
    }
    return load();
  }
}
