import { Router } from '@angular/router';
import {
  Component,
  OnInit,
  HostListener,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import {
  trigger,
  state,
  animate,
  transition,
  style,
} from '@angular/animations';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ToastService } from 'src/app/services/toast.service';
import { StorageServiceService } from 'src/app/services/storage-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('fadeSlideInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(10px)' }),
        animate('200ms', style({ opacity: 1, transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        animate('200ms', style({ opacity: 0, transform: 'translateX(10px)' })),
      ]),
    ]),
    trigger('scrollAnimation', [
      transition('void => *', [
        style({ opacity: 0, transform: 'translateY(-20px)' }),
        animate(
          '500ms ease-in-out',
          style({ opacity: 1, transform: 'translateY(0)' })
        ),
      ]),
    ]),
  ],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  isUserLoggedin = false;
  userDetail: any;
  isSticky: boolean = false;
  showMenu: boolean = false;
  profmenu: boolean = false;
  private isScrolling = false;
  kycStatus: string;
  // isNavbarFixed: boolean = false;

  constructor(
    public toast: ToastService,
    private authService: AuthenticationService,
    public loader: LoaderService,
    public auth: AuthenticationService,
    private elRef: ElementRef,
    private router: Router,
    public storage: StorageServiceService,
  ) {}

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    const clickedInside = this.elRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      // Clicked outside the dropdown or its parent, close it
      this.profmenu = false;
    }
  }

  ngOnInit(): void {
    this.authService.isLoggedIn().subscribe((auth: any) => {
      this.isUserLoggedin = auth;
      if (auth) {
        const data = localStorage.getItem('data');
        if (data) {
          this.userDetail = JSON.parse(data).userData;
        }
      }
    });
    // this.router.navigate(['/']);
  }

  isHomeRoute() {
    return this.router.url === '/';
  }

  ngAfterViewInit(): void {}

  // scrollToTarget(): void {
  //   this.scrollService.scrollToElement('targetBookFxNow');
  // }

  scrollToTarget(): void {
    if (!this.isScrolling) {
      this.isScrolling = true;
      this.router.navigate(['/exchange']).then(() => {
        setTimeout(() => {
          const targetSection = document.getElementById('targetBookFxNow');

          if (targetSection) {
            targetSection.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            });
          }
        }, 100);

        setTimeout(() => {
          this.isScrolling = false;
        }, 1000);
      });
    }
  }

  mobMenu() {
    this.showMenu = !this.showMenu;
  }
  profMenufun() {
    this.profmenu = !this.profmenu;
  }
  // @HostListener('window:scroll', ['$event'])
  // checkScroll() {
  //   this.isSticky = window.pageYOffset >= 103;
  // }
  async logout() {
    const login_token = JSON.parse(localStorage.getItem('data')).login_token;
    const requestData = {
      user_id: JSON.parse(localStorage.getItem('data')).userData.user_id,
    };
    this.loader.presentLoading();
    this.auth.userLogout(requestData, login_token).subscribe(
      (res: any) => {
        if (localStorage.getItem('data')) {
          localStorage.removeItem('data');
        }
        this.loader.dismissLoader();
        // if(this.globalService.jumioContinue$){
        //   this.globalService.jumioContinue$.unsubscribe();
        // }
        // if(this.globalService.closed$){
        //   this.globalService.closed$.unsubscribe();
        //   }

        this.auth.logout();
        // const data = {
        //   user_id: JSON.parse(localStorage.getItem('data')).userData.user_id
        // };
        // this.auth.resetMpin(data).subscribe(
        //   (res) => {
        //     this.auth.logout();
        //   },
        //   (err) => {
        //     console.log(err);
        //   }
        // );
      },
      (error) => {
        //console.log("🚀 ~ file: tab4.page.ts ~ line 112 ~ Tab4Page ~ jumioStatus ~ err", err)
        this.toast.presentToast(error.error.message);
        // this.message = err.error.message;
      }
    );
  }
}
