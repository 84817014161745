import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage-angular';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AES256 } from '@awesome-cordova-plugins/aes-256/ngx';
// import { JwtInterceptor } from './guards/jwt.interceptor';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { Uid } from '@ionic-native/uid/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
import { IonIntlTelInputModule } from 'ion-intl-tel-input';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { DocumentScanner } from '@ionic-native/document-scanner/ngx';
import { IonicSelectableModule } from 'ionic-selectable';
import { JwtInterceptor } from './guards/jwt.interceptor';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { NgOtpInputModule } from 'ng-otp-input';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { PagesComponent } from './pages/pages.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NotifierModule } from 'angular-notifier';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IncludeModule } from './common/include.module';
import { UiControllService } from '../app/services/ui-controll.service';
import { NoCommaModule } from './shared/no-comma.module';
@NgModule({
  declarations: [
    AppComponent,
    // PagesComponent,
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicStorageModule.forRoot(),
    IonicModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    IonIntlTelInputModule,
    IonicSelectableModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    NotifierModule,
    IncludeModule,
    NoCommaModule
  ],
  providers: [
    AES256,
    Camera,
    Geolocation,
    NativeGeocoder,
    DocumentScanner,
    GooglePlus,
    Facebook,
    Uid,
    UniqueDeviceID,
    Diagnostic,
    AndroidPermissions,
    SocialSharing,
    BackgroundMode,
    Keyboard,
    NgOtpInputModule,
    UiControllService,
    Network,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
