import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  isLoader = false;
  constructor(public loadingController: LoadingController) { }

  async presentLoading() {
    if(this.isLoader){
      return
    }
    this.isLoader = true;
    const loading = await this.loadingController.create({
      spinner: 'crescent',
      cssClass: 'loading my-custom-loader',
      message: 'Please wait...',
      duration: 1000
    });
    await loading.present();

    await loading.onDidDismiss().then((res)=>{
      this.isLoader = false;
    })

    // console.log('Loading dismissed!');
  }
  async presentLoading1() {
    if(this.isLoader){
      return
    }
    this.isLoader = true;
    const loading = await this.loadingController.create({
      spinner: 'crescent',
      cssClass: 'loading my-custom-loader',
      message: 'Please wait...',
      duration: 10000000000
    });
    await loading.present();

    await loading.onDidDismiss().then((res)=>{
      this.isLoader = false;
    })
    // console.log('Loading dismissed!');
  }
  async presentLoadingWithOptions() {
    const loading = await this.loadingController.create({
      spinner: null,
      duration: 2000,
      message: 'Click the backdrop to dismiss early...',
      translucent: true,
      cssClass: 'custom-class custom-loading',
      backdropDismiss: true
    });
    await loading.present();

    const { role, data } = await loading.onDidDismiss();
    // console.log('Loading dismissed with role:', role);
  }

  dismissLoader() {
    this.loadingController.dismiss().then((response) => {
        // console.log('Loader closed!', response);
        this.isLoader = false;
    }).catch((err) => {
        // console.log('Error occured : ', err);
    });
}
}

