import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private url = environment.baseUrl;
  public modaldata$ = new BehaviorSubject<any>({modalIcon:'',modalTitle:'',status:false});
  constructor(private http: HttpClient) {}


  //Country list
  getCountryList(id: number) {
    return this.http.get(this.url + '/country/list/' + id);
  }

  //currency list
  getCurrency(payload: any) {
    return this.http.post<any>(this.url + '/web/list-currency', payload);
  }

  getCurrencys(payload: any) {
    return this.http.post<any>(this.url + '/web/list-currencys', payload);
  }

  //news api
  getNews(payload: any) {
    return this.http.post<any>(this.url + '/web/list-blog', payload);
  }

  fixedRate(payload: any) {
    return this.http.post<any>(this.url + '/web/listtransfer-servicefee', payload);
  }

  //exchangeapi
  getexchange(payload: any) {
    return this.http.post<any>(this.url + '/web/exchange-currency', payload);
  }

  //contactus create api
  addContact(payload: any) {
    return this.http.post<any>(this.url + '/contactus/create', payload);
  }

  getfaq(payload: any) {
    return this.http.post<any>(this.url + '/web/faq-list', payload);
  }

  fixedrates(payload: any) {
    return this.http.post<any>(this.url + '/web/listbycurrency', payload);
  }

  getpdf(payload: any) {
    return this.http.post<any>(this.url + '/web/page-list', payload);
  }

  //news by id
  getnewsbyId(id: number) {
    return this.http.get(this.url + '/web/list-blog/' + id);
  }
  modalDataSub(){
    return this.modaldata$.asObservable()
  }
}
