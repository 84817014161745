import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap, switchMap, timeout, catchError } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, of, Subject, throwError } from 'rxjs';
import { StorageServiceService } from './storage-service.service';
import { HttpService } from './http.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AES256 } from '@awesome-cordova-plugins/aes-256/ngx';
import { Platform } from '@ionic/angular';
import { Meta, Title } from '@angular/platform-browser';
const TOKEN_KEY = 'my-token';
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  isLoginSubject$ = new BehaviorSubject<boolean>(this.hasToken());
  chatScreen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  token = '';
  private secureKey: string; // Any string, the length should be 32
  private secureIV: string;
  text: string;
  EncryptedData: string;
  weburl=environment.apiUrlWeb;
  constructor(
    public store: StorageServiceService,
    private http: HttpClient,
    private httpService: HttpService,
    private aes256: AES256,
    private platform: Platform,
    public route: Router,
    private title: Title, private meta: Meta
  ) {}
  async loadToken() {
    const token = await this.store.get('TOKEN_KEY');
    if (token && token.value) {
      // console.log('set token: ', token.login_token);
      this.token = token.login_token;
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  login(credentials): Observable<any> {
    // console.log(credentials);
    return this.http.post(environment.apiUrlWeb + 'auth/login', credentials).pipe(
      map((data: any) =>{
        // console.log("🚀 ~ file: authentication.service.ts ~ line 49 ~ AuthenticationService ~ map ~ data", data)
        if(data.status === 'FAIL'){
          return data
         }
        return data.data}),
      switchMap((token: any) => {
        if(token.status === 'FAIL'){
          return throwError(token)
         }
        localStorage.setItem('data', JSON.stringify(token));
        localStorage.setItem('token', token?.login_token);
        this.store.store('mpinData', {mpinToken:token?.login_token,userData:token.userData}).then(res => {
        });
        this.isLoginSubject$.next(true);
        return from(this.store.store('TOKEN_KEY', token.login_token));
      }),
      tap((_) => {
       // this.isAuthenticated.next(true);
      })
    );
  }

  SocialRegistration(registerationData): Observable<any> {
    // console.log(registerationData);
    return this.http
      .post(environment.apiUrls + 'auth/register', registerationData)
      .pipe(
        map((data: any) => data.data),
        switchMap((userData) => {
          localStorage.setItem('userData', JSON.stringify(userData));
          // localStorage.setItem('token', token.MpinExpire);
          return from(this.store.store('userData', userData));
        })
      );
  }

  board(slider): Observable<any> {
    return this.httpService.get('contain/slider/' + slider);
  }

  reg(postData: any): Observable<any> {
    return this.httpService.post('auth/register', postData);
  }
  regNewProcess(postData: any): Observable<any> {
    return this.http.post(this.weburl+'auth/register', postData);
  }
  resendOtp(postData: any): Observable<any> {
    return this.httpService.post('auth/resendOtp', postData);
  }

  // verify(postData: any): Observable<any> {
  //   return this.httpService.post('/auth/verifyPin', postData);
  // }
  verify(postData: any): Observable<any> {
    return this.http.post(environment.apiUrlWeb + 'auth/verifyPhone', postData);
  }

  mpinCreate(postData: any): Observable<any> {
    return this.httpService.puts('auth/updateMpin', postData);
  }

  mpinLogin(postData: any): Observable<any> {
    return this.httpService.post('auth/loginMpin', postData);
  }



  getuserData(token: any): Observable<any> {
    return this.httpService.gets('auth/profileView', token);
  }

  verficationEmailMobile(data){
    return this.httpService.posts('/auth/email-phone-verification',data,'')
  }
  verifyPhoneEmailPin(data){
    return this.httpService.posts('auth/verify-phone-email-pin',data,'')
  }
  updateEmailMobile(data){
    return this.httpService.posts('/auth/update-email-phone',data,'')
  }
  updateProfile(param, token): Observable<any> {
    return this.httpService.put('/auth/updateProfile', param, token);
  }

  createRecipient(param, token): Observable<any> {
    return this.httpService.posts('recipient/create', param, token);
  }
  validateAddress(param, token): Observable<any> {
    return this.httpService.posts('recipient/validate-address', param, token);
  }
  updateRecipient(param, token): Observable<any> {
    return this.httpService.put('recipient/update', param, token);
  }
  stateList(param, token): Observable<any> {
    return this.httpService.posts('recipient/state-list', param, token);
  }
  cityList(param, token): Observable<any> {
    return this.httpService.posts('recipient/city-list', param, token);
  }
  systemSettingList(param, token): Observable<any> {
    return this.httpService.posts('systemSetting/list', param, token);
  }

  uploadImageDoc(param, token): Observable<any> {
    return this.httpService.put('auth/uploadImageAndDoc', param, token);
  }

  docDetail(param, token): Observable<any> {
    return this.httpService
      .put('auth/getDocDetails', param, token)
      .pipe(timeout(15000));
  }
  getPdf(url){
    return this.httpService.getPdf(url);
  }
  getDocType(token: any): Observable<any> {
    return this.httpService.gets('auth/docType', token);
  }

  getStoreList(): Observable<any> {
    return this.httpService.postss('recipient/store-list');
  }

  createStoreRecipient(data:any): Observable<any> {
    return this.httpService.postRecipient('recipient/create-self-recipient', data);
  }

  getDocument(token: any): Observable<any> {
    return this.httpService.gets('contain/slider/kyc_document_icons', token);
  }
  getCountList(): Observable<any> {
    return this.httpService.get('auth/jumioCountryList');
  }

  getThunesCountList(): Observable<any> {
    return this.httpService.get('country/list');
  }

  countrylist(param, token): Observable<any> {
    return this.httpService.posts('recipient/countrylist', param, token);
  }

  payerList(param, token): Observable<any> {
    return this.httpService.posts('payer/list', param, token);
  }
  payerV2List(param, token): Observable<any> {
    return this.httpService.posts('payer/v2/list', param, token);
  }
  bankDetailById(param, token): Observable<any> {
    return this.httpService.posts('payer/bank_detail_by_id', param, token);
  }

  getKyc(token) {
    return this.httpService.gets('auth/jumioToken',token);
  }


  jumioStatus(param, token): Observable<any>{
    return this.httpService.posts('auth/jumioStatus',param, token);
  }
  blogList(param, token): Observable<any> {
    return this.httpService.posts('blog/list', param, token);
  }

  branchList(param, token): Observable<any> {
    return this.httpService.posts('branch/list', param, token);
  }

  resetPassword(param, token): Observable<any> {
    return this.http.post(this.weburl+'auth/changePassword', param, token);
  }

  forresetPassword(param): Observable<any> {
    return this.http.post(this.weburl+'auth/changePassword', param);
  }

  newsLetter(param, token): Observable<any> {
    return this.httpService.posts('auth/newsletter', param, token);
  }

  fraudAlerts(param, token): Observable<any> {
    return this.httpService.posts('auth/fraudAlert', param, token);
  }

  dynamicContent(id, token): Observable<any> {
    return this.httpService.gets('contain/list/' + id, token);
  }
  dynamicContent1(id): Observable<any> {
    return this.httpService.get('contain/list/' + id).pipe(map((res:any)=> res.data[0].contain_image));
  }

  brachListByPostalCode(id, token): Observable<any> {
    return this.httpService.gets('order/auspost/' + id, token);
  }

  dynamicContentList(): Observable<any> {
    return this.httpService.posts('contain/list', {limit: 20, offset: 0},'token').pipe(map(((res:any)=>{
      return this.dynamicSort (res.data.result.filter(function(item){
        return [17,21,66,67].indexOf(item.id) > -1;
      }));
    })));
  }
  dynamicSort(arr) {
    arr.sort(function(a, b) {
      var keyA = new Date(a.contain_order),
        keyB = new Date(b.contain_order);
      // Compare the 2 dates
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    return arr
 }
  brachDetailsById(id, token): Observable<any> {
    return this.httpService.gets('blog/list/' + id, token);
  }

  oderDetails(id, token): Observable<any> {
    return this.httpService.gets('order/list/' + id, token);
  }

  logout(): Promise<void> {
    this.store.clear().then(() => {
    //  this.isAuthenticated.next(null);
      localStorage.clear()
      this.route.navigate(['login']).then(()=>{
        this.route.navigate(['login'],{ replaceUrl: true });
      });
    });
    // window['kommunicate'].logout();
    // window['kommunicate'] = undefined;
    this.isLoginSubject$.next(false);
    return this.store.removeStorageItem('TOKEN_KEY');
  }
  changePassword(){
    this.store.removeStorageItem('mpinData').then(()=>{
      this.route.navigate(['login'], { replaceUrl: true });
    })
    return this.store.removeStorageItem('TOKEN_KEY');
  }
  failedToMpin(){
    this.store.clear().then(() => {
      //this.isAuthenticated.next(false);
      // this.route.navigate(['login']);
      this.route.navigate(['login'], { replaceUrl: true });
    });
  }

  logoutMpin(): Promise<void> {
    this.store.clear().then(() => {
     // this.isAuthenticated.next(false);
      // this.route.navigate(['login']);
    });

    return this.store.removeStorageItem('TOKEN_KEY');
  }
  //list of order transaction
  getAllOrder(page, token) {
    return this.httpService.posts('order/list', page, token);
  }

  // list of recipients
  getAllRecipients(key, token) {
    return this.httpService.posts('recipient/list', key, token);
  }
  deleteRecipient(id, token) {
    return this.httpService.delete('recipient/del/'+id, token);
  }

  getExchangeRate(key, token) {
    return this.httpService.posts('currency/exchange', key, token);
  }

  getCurrencyLists(key, token) {
    return this.httpService.posts('currency/list', key, token);
  }

  getServiceFees(key, token) {
    return this.httpService.posts('fee/listbycurrency', key, token);
  }

  getRecipient(id: any, token: any) {
    return this.httpService.gets('recipient/list/' + id, token);
  }
  //remittance/list
  getRemittanceList(body: any, token: any) {
    return this.httpService.posts('remittance/list',body, token);
  }

  //Currency exchange API
  getExchangeCurr(body, token) {
    return this.httpService.posts('currency/exchange', body, token);
  }

  //Post Order Request  API
  postOrderReq(body, token) {
    return this.httpService.posts('order/request', body, token);
  }

  postAusOrderReq(body, token) {
    return this.httpService.posts('order/request-auspost', body, token);
  }
  // cancle order

  cancelOrder(body, token){
    return this.httpService.posts('order/cancel-order', body, token);
  }
  resetMpin(id) {
    return this.http.put(environment.apiUrls + 'auth/resetMpin', id);
  }

  getIsdList() {
    return this.httpService.get('auth/jumioCountryList');
  }
  refreshToken(token){
    return this.httpService.refreshToken('auth/refresh-token',token).pipe(
      map((res:any)=> {return res.data.userData.newToken})
    )
  }
  userLogout(body,token){
  //  return this.httpService.gets('auth/logout',token)
    return this.httpService.put('auth/logout', body,token)
  }
  getCreditCard(id){
    return this.httpService.gets('card/getCustomerCard/'+id,'');
  }
  createCard(body){
    return this.httpService.posts('card/create', body, 'token');

  }
  updateOrderStripe(param,token): Observable<any>{
    return this.httpService.put("card/updatestripe",param,token);
   }
   createTillPayment(param,token): Observable<any>{
    return this.httpService.put("payment/create",param,token);
   }
   saveCardList(token): Observable<any>{
    return this.httpService.gets("payment/save-card-list",token);
   }
   deleteSaveCard(body,token){
    return this.httpService.put('payment/delete-save-card',body, token);
   }
   userBlock(body){
    return this.httpService.posts('auth/user-blocked', body, 'token');

  }
  isLoggedIn(): Observable<boolean> {
    return this.isLoginSubject$.asObservable();
  }
  verifyEmailToken(body: any){
    return this.http.post(this.weburl+'auth/verifyEmail',body);
  }
  createOnfidoToken(): Observable<any> {
    return this.httpService.getsWeb('kyc/get-onfido-token','');
  }
  createApplicantData(body: any){
    return this.httpService.postsWeb('kyc/create-applicant',body,'');
  }
  updateKycDocStatus(body: any){
    return this.httpService.postsWeb('kyc/update/jumio-status',body,'');
  }
  tinySendOtp(tinycode:any){
    return this.httpService.get(`order/send-otp/${tinycode}`)
  }
  verifyTinyOtp(postData: any): Observable<any> {
    return this.httpService.post('order/verify-otp', postData);
  }
  checkTinyCode(tinyCode: any): Observable<any> {
    return this.httpService.get('order/check-code/'+tinyCode);
  }
  updateStorage(payload:any){
    let data:any=localStorage.getItem('data');
    if(data){
      data=JSON.parse(data);
      const userData={...data.userData,...payload}
      localStorage.setItem('data',JSON.stringify({login_token:data?.login_token,userData:userData}))
      this.isLoginSubject$.next(true);
    }
  }
  _updateTitle(title: string) {
    this.title.setTitle(title);
  }
  _updateOgUrl(url: string) {
    this.meta.updateTag({  property:'og:url',content: url });
    this.meta.updateTag({ property:"twitter:url" ,content: url});
  }
  _updateOgTitle(title:string){
    this.meta.updateTag({ property:"og:title" ,content:title});
    this.meta.updateTag({ name:'twitter:title' ,content:title});
  }
  _updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc }),
    this.meta.updateTag({ property:"og:description", content:desc}),
    this.meta.updateTag({ name:'twitter:description', content:desc});
  }
  _updateCommonTag(){
    this.meta.updateTag({ property:"og:type" ,content:'website'});
    this.meta.updateTag({ name:"twitter:card", content:'summary'})
  }
  _updateOgImage(image:any){
    this.meta.updateTag({ property:"og:image", content:image}),
    this.meta.updateTag({ name:'twitter:image', content:image})
  }
  _updateDomain(domain:any){
    this.meta.updateTag({property:"twitter:domain" ,content:domain});
  }
  _updateKeyword(key: string) {
    this.meta.updateTag({ name: 'keywords', content: key })
  }
  _deleteMetaTags(){
    this.meta.removeTag("name='description'")
    this.meta.removeTag("property='twitter:url'")
    this.meta.removeTag("name='twitter:title'")
    this.meta.removeTag("name='twitter:description'")
    this.meta.removeTag("name='twitter:card'")
    this.meta.removeTag("name='twitter:image'")
    this.meta.removeTag("property='twitter:domain'")
    this.meta.removeTag("property='og:url'")
    this.meta.removeTag("property='og:title'")
    this.meta.removeTag("property='og:description'")
    this.meta.removeTag("property='og:type'")
    this.meta.removeTag("property='og:image'")
  }
  _defaultMetaTags(){
    this.title.setTitle('Pico Money: best rate I always have stocks I deliver by post');
    this.meta.updateTag({  property:'og:url',content: window.location.href });
    this.meta.updateTag({ property:"twitter:url" ,content: window.location.href});
    this.meta.updateTag({ property:"og:title" ,content:'Pico Money: best rate I always have stocks I deliver by post'});
    this.meta.updateTag({ name:'twitter:title' ,content:'Pico Money: best rate I always have stocks I deliver by post'});
    this.meta.updateTag({ name: 'description', content: 'Get the best exchange rates for your currency. Fast, secure, and reliable money exchange service' }),
    this.meta.updateTag({ property:"og:description", content:'Get the best exchange rates for your currency. Fast, secure, and reliable money exchange service'}),
    this.meta.updateTag({ name:'twitter:description', content:'Get the best exchange rates for your currency. Fast, secure, and reliable money exchange service'});
    this.meta.updateTag({ property:"og:type" ,content:'website'});
    this.meta.updateTag({ name:"twitter:card", content:'summary'});
    this.meta.updateTag({property:"twitter:domain" ,content:location.origin});
    this.meta.updateTag({ name: 'keywords', content: 'Money Exchange, Best Rates, Currency Exchange, Secure, Reliable' })
  }
  private hasToken(): boolean {
    return !!localStorage.getItem('data');
  };
  captureLogs(body:any) {
    let userDetail:any=localStorage.getItem('data');
    this.http.post(this.weburl+'auth/web-log',{...body,user_id:userDetail?JSON.parse(userDetail).userData.user_id:null},{headers: {
      'x-auth': localStorage.getItem('token') || '',
      'Access-Control-Allow-Origin': '*',
      isweb: 'true',
    },}).subscribe({
      next:(res:any)=>{},
      error:(err:any)=>{}
    })
  }
}

