// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiUrl: 'http://localhost:8100/',
  baseUrl: 'https://pico-rest-api-stage.herokuapp.com/api/v1/app',
  s3BucketUrl: 'https://picopay-project.s3.amazonaws.com/',
  //apiUrls: 'https://pico-rest-api-qa.herokuapp.com/api/v1/app/',
   apiUrls: 'https://pico-rest-api-stage.herokuapp.com/api/v1/app/',
  //apiUrls: 'https://pico-rest-api-stage.herokuapp.com/api/v1/app/',
  apiUrlWeb: 'https://pico-rest-api-stage.herokuapp.com/api/v1/web/',
  tillPayemntSecureKey:'xCcZLTrr8sio2GErl9OX',
   firebaseConfig: {
    apiKey: "AIzaSyDqsxbdlgMsq6OdFN3UYdHv8Lss_-NZWx8",
    authDomain: "core-avenue-328703.firebaseapp.com",
    projectId: "core-avenue-328703",
    storageBucket: "core-avenue-328703.appspot.com",
    messagingSenderId: "897755274555",
    appId: "1:897755274555:web:b508f80544b6e45a08ab1e",
    measurementId: "G-258JRCF5HB"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
