import { Injectable } from '@angular/core';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
  HttpHeaders,
} from '@angular/common/http';
import { EMPTY, from, Observable, of, throwError } from 'rxjs';
import { StorageServiceService } from '../services/storage-service.service';
import { environment } from 'src/environments/environment';
import { catchError, debounceTime, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private url = environment.apiUrls;

  constructor(private localStorageToken: StorageServiceService,   private router: Router,

    private authService: AuthenticationService,
    public storage: StorageServiceService,) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {


    return from(this.handle(request, next))
    //console.log("🚀 ~ file: jwt.interceptor.ts ~ line 47 ~ JwtInterceptor ~ request", request)
    // .pipe(
    //   map((res:any)=> {
    //     if(res.status === 203){
    //         res.error = { message : res.body.data };
    //         return throwError(res);
    //         // return of(new HttpResponse({
    //         //   body: res
    //         // }));
    //     }
    //     return res
    //   }),

    //     catchError((error: HttpErrorResponse) => {
    //         console.log("🚀 ~ file: jwt.interceptor.ts ~ line 57 ~ JwtInterceptor ~ catchError ~ error", error)
    //         return throwError(error);
    //     })
    // )



      // .pipe(catchError((err: any) => {
      //   if (err instanceof HttpErrorResponse) {
      //     if (err.status === 400) {
      //       console.log(err);
      //     }
      //   }

      //   return new Observable<HttpEvent<any>>();
      // }));
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    // if your getAuthToken() function declared as "async getAuthToken() {}"
    const token = this.localStorageToken.getToken();
    const data = localStorage.getItem('data');
    if (data) {
      if (token && !req.url.includes('auth/refresh-token')) {
        const tokenDecode = JSON.parse(atob(token.split('.')[1]));
      //  console.log("🚀 ~ file: jwt.interceptor.ts ~ line 75 ~ JwtInterceptor ~ handle ~ tokenDecode.exp", tokenDecode.exp)

        if (!await this.tokenExpired(tokenDecode.exp)) {
          // const authReq = req.clone({
          //   headers: new HttpHeaders({
          //     'Content-Type':  'application/json',
          //     'x-auth':  localStorage.getItem('token'),
          //   })
          // });

          // console.log('Intercepted HTTP call', authReq);
          // return next.handle(authReq).toPromise()


        } else {
          return next.handle(req).toPromise()

        }
      } else {
        return next.handle(req).toPromise()
      }


    } else {
      return next.handle(req).toPromise()
    }

    // Important: Note the .toPromise()
   // return next.handle(authReq).toPromise()
  }
  //  private tokenExpired(expiration): boolean {
  //   return Math.floor(new Date().getTime() / 1000) >= expiration;
  // }
  public tokenExpired = async (expiration) => {
    // return Math.floor(new Date().getTime() / 1000) >= expiration;
    if (Math.floor(new Date().getTime() / 1000) >= expiration-100) {
      this.authService.logout();
      return false
      // try {
      //   // const response = await lastValueFrom(this.authService.refreshToken());
      //  let login_token= JSON.parse(
      //     localStorage.getItem('data')
      //   ).login_token;
      // // console.log("🚀 ~ file: auth.guard.ts ~ line 50 ~ AuthGuard ~ tokenExpired= ~ login_token", login_token)
      //   //let response: any = await this.authService.refreshToken(localStorage.getItem('token')).pipe(debounceTime(100)).toPromise();
      //   let response: any = await this.authService.refreshToken(login_token).pipe(debounceTime(100)).toPromise();
      //   localStorage.setItem('token', response);
      //   this.storage.get('mpinData').then((res: any) => {
      //     res.mpinToken = response;
      //     this.storage.store('mpinData', res).then(res => {
      //       return false
      //     });

      //   });

      // }
      // catch (ex) {
      //   //console.log("🚀 ~ file: auth.guard.ts ~ line 50 ~ AuthGuard ~ tokenExpired=async ~ ex", ex)
      //   return true
      // }
    } else {
      // return Math.floor(new Date().getTime() / 1000) >= expiration;
      return true
    }

  }
}
