import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  apiTimeout: number = 15000;
	apiTimeoutLarge: number = 300000;
  public user:any = {}
  mPinPattern: any = /^([0-9]{4})?$/;
  private history = [];
  closed$ = new Subject<any>();
  jumioContinue$ = new Subject<any>();
  constructor(    private diagnostic: Diagnostic,  private router: Router) { }


  checkCameraPermision(){
    let permissionStatus = this.diagnostic.permissionStatus;
    return  this.diagnostic.getCameraAuthorizationStatus()
        .then((state) => {
          switch(state){
            case permissionStatus.GRANTED:
              // console.log("Permission granted to use the camera");
              return true
              break;
            case permissionStatus.NOT_REQUESTED:
              //Here should I now call the PermissionRequest function
              // console.log("Permission denied to use the camera - ask again?");
              return true
              break;
            case permissionStatus.DENIED_ALWAYS:
              // console.log("Permission permanently denied to use the camera - guess we won't be using it then!");
              return false
              break;
            default:
            return false
          }
        }).catch(e => console.error(e));
  }

  public loadRouting(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({urlAfterRedirects}: NavigationEnd) => {
        this.history = [...this.history, urlAfterRedirects];
      });
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 2] || '/index';
  }
  continue(){
    this.jumioContinue$.next(true)
  }
}
