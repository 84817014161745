import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { DataService } from 'src/app/shared/services/data.service';
import { isTemplateExpression } from 'typescript';
@Component({
  selector: 'app-copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss']
})
export class CopyrightComponent implements OnInit {
  datapdf: any = [];
  loading = false;
  searchData: string = '';
  totalCount: any;
  pp:any;
  ca:any;
  pds:any;
  fs:any;
  page = 1;
  limit = 10;
  docRegex = /\.(pdf|docx)$/i;
  currentYear=new Date().getFullYear()
  constructor(private dataService: DataService,
    private notifier: NotifierService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.onfetchData();
  }

  onfetchData() {
    let payload = {
     limit: 100,
     offset: 0
    }
    this.loading = true;
    this.datapdf = null;
    try {
      this.dataService.getpdf(payload).subscribe((res: any) => {
        this.datapdf = res.data.result;
        this.datapdf.sort(function(a:any, b:any) {
          return a.contain_order - b.contain_order;
       });
        this.loading = false;
        this.totalCount = res.data.totalCount;
      })
    } catch {
      this.loading = false;

    }
  }

  // onfetchpdf() {
  //   for (const iterator of this.datapdf) {
  //      if (iterator.id == 21) {
  //       this.pp =iterator.contain_image
  //      }
  //   }
  // }

  // onfetchca() {
  //   for (const iterator of this.datapdf) {
  //      if (iterator.id == 17) {
  //       this.ca =iterator.contain_image
  //      }
  //   }
  // }

  // onfetchpds() {
  //   for (const iterator of this.datapdf) {
  //      if (iterator.id == 66) {
  //       this.pds =iterator.contain_image
  //      }
  //   }
  // }

  // onfetchfs() {
  //   for (const iterator of this.datapdf) {
  //      if (iterator.id == 67) {
  //       this.fs =iterator.contain_image
  //      }
  //   }
  // }
}
